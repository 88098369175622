import React, { ImgHTMLAttributes } from "react"
import { getTieupImgSrc } from "./getTieupImgSrc"
import { SizeType } from "./SizeType"

const TieupImg: React.FC<
  ImgHTMLAttributes<HTMLImageElement> & {
    size?: SizeType
    alt?: string
  }
> = ({ size, src, alt, ...rest }) => {
  if (!src) return null

  const isFromApi = !/^http/.test(src) && !/^\/img/.test(src)

  return (
    <img
      {...rest}
      src={isFromApi ? getTieupImgSrc(src, size) : src}
      alt={alt}
    />
  )
}

export default TieupImg
