import { useContext } from "react"
import { globalStoreContext } from "../../store/GlobalStore"
import { EmptyUserData, FirebaseUserData } from "../../../types"
import { ApiUserData } from "src/models/user/ApiUserData"
import { useLoading } from "./useLoading"
import { fetchMyUserInfo } from "../../models/user/fetchMyUserInfo"

/** ユーザーステートの設定 */
const createUserState = (
  firebaseUser: FirebaseUserData | EmptyUserData,
  apiUser: ApiUserData,
) => {
  // ユーザードメインのstatusがACTIVEでない
  if (apiUser.status !== 0) return "guest"
  // 通知用メールアドレスが未認証
  if (!apiUser.mail_address) return "guest"

  return "member"
}

const useApiUserData = () => {
  const {
    globalDispatch,
    globalStore: {
      user: { firebaseUserData },
    },
  } = useContext(globalStoreContext)
  const { fullScreenLoading } = useLoading()

  const updateApiUserData = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (!firebaseUserData.id) return resolve("")
      try {
        // ローディング
        fullScreenLoading(true)
        // APIユーザー情報の取得
        const userDataFromApi = await fetchMyUserInfo()

        userDataFromApi.member_status = createUserState(
          firebaseUserData,
          userDataFromApi,
        )
        userDataFromApi.name =
          userDataFromApi.name === null ? "ゲストさん" : userDataFromApi.name
        globalDispatch({ type: "user/apiUserData", payload: userDataFromApi })
        resolve(userDataFromApi)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        // window.location.href = "/404"
        reject(error)
      } finally {
        fullScreenLoading(false)
      }
    })
  }

  return {
    updateApiUserData,
  }
}

export { useApiUserData }
