/**
 * APIからユーザー情報を取得
 */
import React, { useContext, useEffect } from "react"
import { globalStoreContext } from "src/store/GlobalStore"
import { useLoading } from "./hooks/useLoading"
import { useTieupToast } from "./hooks/useTieupToast"
import { registerFcmToken } from "../models/user/registerFcmToken"
import { checkMailRequest } from "../models/user/checkMailRequest"

type Props = {
  isFirebaseLoggedIn: boolean
  fcmToken: string
}

const ApiUserData: React.FC<Props> = ({
  children,
  isFirebaseLoggedIn,
  fcmToken,
}) => {
  const {
    globalDispatch,
    globalStore: {
      user: { clientState },
    },
  } = useContext(globalStoreContext)
  const { fullScreenLoading } = useLoading()
  const { addToastInfo } = useTieupToast()

  async function setupApiUser() {
    try {
      fullScreenLoading(true)
      /** FCMトークンの登録 */
      await registerFcmToken(fcmToken)

      /** 認証情報の確認 */
      const { hasActiveRequest } = await checkMailRequest()

      if (hasActiveRequest && clientState === 2) {
        addToastInfo(
          <a href="/auth/change-notification-email">
            メールアドレスの認証が済んでませんのでメールを確認ください。メールが届いていない方はこちら
          </a>,
          false,
        )
      }

      /** グローバル登録 */
      globalDispatch({
        type: "user/hasActiveRequest",
        payload: hasActiveRequest,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    } finally {
      fullScreenLoading(false)
    }
  }

  useEffect(() => {
    /** Firebase AuthにログインしたらAPIからユーザー情報を取得する */
    if (isFirebaseLoggedIn) {
      setupApiUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirebaseLoggedIn])

  return <>{children}</>
}

export default ApiUserData
