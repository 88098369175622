import { BaseGetInputModel } from "../../../models/http/InputModel/BaseGetInputModel"

type Params = {
  since?: number
}

export class NotificationGetInputModel extends BaseGetInputModel {
  constructor(private params: Params) {
    super()
  }

  get query(): URLSearchParams {
    const searchParams = new URLSearchParams()

    if (this.params.since !== undefined) {
      searchParams.set("since_days", this.params.since.toString())
    }

    return searchParams
  }
}
