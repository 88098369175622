import { MypageUserMailCheckRequestGetInputModel } from "./GetInputModel"
import { MypageUserMailCheckRequestGetResponse } from "./GetResponse"
import { httpGet } from "../../../../../../libs/http/httpGet"

export const getMypageMailCheckRequest = async (
  model: MypageUserMailCheckRequestGetInputModel,
): Promise<MypageUserMailCheckRequestGetResponse> => {
  return httpGet<MypageUserMailCheckRequestGetResponse>(
    "/v1/mypage/user/mail/check_request",
    model,
  )
}
