import React, { useEffect, useState } from "react"
import { useMount } from "react-use"
import NextNProgress from "nextjs-progressbar"
import { AppPropsType } from "next/dist/shared/lib/utils"
import initFirebase from "src/utils/auth/initFirebase"
import { ToastProvider } from "react-toast-notifications"
import { ErrorBoundary } from "react-error-boundary"
import { initAPIMockOnServer, initAPIMockOnClient } from "../../mock-api"

import "src/styles/style.scss"
import "src/styles/override.scss"
import "src/styles/modal.scss"
import "src/styles/editor.scss"
import "src/styles/react-tags.scss" // ライブラリのスタイルのオーバーライド
import "src/styles/react-confirm-alert.scss" // ライブラリのスタイルのオーバーライド
import "draft-js/dist/Draft.css"
import "rc-rate/assets/index.css" // レーティング用のデフォルトのスタイル
import "rc-slider/assets/index.css"
import "swiper/swiper-bundle.css"

import FullScreenLoading from "src/components/common/loading/FullScreenLoading"
import GlobalStoreProvider from "src/store/GlobalStore"
import ScrollLock from "src/components/common/ScrollLock"
import { serviceWorkerRegister } from "src/utils/ServiceWorker"
import FirebaseUserData from "src/components/FirebaseUserData"
import ClientUserState from "src/components/ClientUserState"
import ErrorFallback from "src/components/ErrorFallback"
import TieupToast from "src/components/common/TieupToast"
import FCMNotification from "src/components/FCMNotification"
import color from "src/config/color"
import PurchaseGiftStoreProvider from "../store/PurchasedGiftItemStore"

initFirebase()

export default function MyApp({ Component, pageProps }: AppPropsType) {
  // APIモックが有効な場合は、mswの初期化を待ってレンダリングする。
  // 無効な場合はすぐにレンダリングを始める。
  const [shouldRender, setShouldRender] = useState(
    process.env.NEXT_PUBLIC_API_MOCKING !== "enabled",
  )
  useMount(() => {
    serviceWorkerRegister(`/firebase-messaging-sw.js`)
  })

  if (process.env.NEXT_PUBLIC_API_MOCKING && typeof window === "undefined") {
    initAPIMockOnServer()
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_API_MOCKING) {
      initAPIMockOnClient().then(() => {
        setTimeout(() => {
          setShouldRender(true)
          // mswは初期化後も少し時間が必要な模様。1000msecだとモックできないリクエストがあった。
        }, 1500)
      })
    }
  }, [])

  if (!shouldRender) {
    return null
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GlobalStoreProvider>
        <ToastProvider
          /** デフォルトの autoDismissTimeout は 5000msc */
          placement="bottom-right"
          components={{ Toast: TieupToast }}
        >
          <FirebaseUserData>
            <ClientUserState>
              <ScrollLock />
              <NextNProgress color={color.spectrum.tieupTheme} />
              <FullScreenLoading />
              <FCMNotification />
              <PurchaseGiftStoreProvider>
                <Component {...pageProps} />
              </PurchaseGiftStoreProvider>
            </ClientUserState>
          </FirebaseUserData>
        </ToastProvider>
      </GlobalStoreProvider>
    </ErrorBoundary>
  )
}
