const BACKWARD_QUERY_PARAMS = "BACKWARD"

const routes = {
  // 404
  notFound: () => `/404`,
  // 500
  systemError: () => `/500`,

  /**
   * 1系
   */
  // 1-1 ホーム
  home: () => `/`,

  /**
   * 2系
   */
  // 2-1 作品検索（ジャンル）
  searchGenres: () => `/search/genres`,
  // 2-2 作品検索結果 - 一覧
  tieups: () => `/tieups`,

  // ランキング詳細
  ranking: () => `/ranking`,

  /**
   * 4系
   */
  // 4-1 マイページ
  mypage: () => `/mypage`,
  // 4-1 マイページ 閲覧履歴
  mypageHistory: () => `/mypage/history`,
  // 4-2 マイページ - プロフィール編集
  mypageEditProfile: () => `/mypage/edit-profile`,
  // 4-3 マイページ - フォロー一覧
  mypageFollowing: () => `/mypage/following`,
  // 4-4 マイページ - 本棚
  bookshelf: () => `/mypage/bookshelf`,
  // 4-5 マイページ - 購入履歴
  purchased: () => `/mypage/purchased`,

  // たいあっぷギフト管理 - アピールコメント
  mypageGift: () => "/mypage/gift/",

  // たいあっぷギフト管理 - ギフト購入
  mypageGiftPurchase: (returnUrl?: string | Location) =>
    returnUrl
      ? `/mypage/gift/purchase?return_url=${returnUrl}`
      : "/mypage/gift/purchase",
  // たいあっぷギフト管理 - 所持ギフト
  mypageGiftOwned: () => "/mypage/gift/owned",

  // たいあっぷギフト管理 - 贈ったギフト
  mypageGiftSent: () => "/mypage/gift/sent",

  // たいあっぷギフト管理 - もらったギフト
  mypageGiftReceived: () => "/mypage/gift/received",

  // たいあっぷギフト管理 - リクエスト
  mypageGiftRequest: () => "/mypage/gift/request",

  // たいあっぷギフト管理 - リクエスト - 送信したリクエスト
  mypageGiftRequestSent: () => "/mypage/gift/request/sent",

  // たいあっぷギフト管理 - リクエスト - 送信したリクエスト詳細
  mypageGiftRequestSentDetail: (commissionId: number) =>
    `/mypage/gift/request/sent/${commissionId}`,

  // たいあっぷギフト管理 - リクエスト - 送信したリクエスト詳細 - ギフト送付
  mypageGiftRequestSentDetailSendGift: (commissionId: number) =>
    `/mypage/gift/request/sent/${commissionId}/send-gift`,

  // たいあっぷギフト管理 - リクエスト - 受信したリクエスト
  mypageGiftRequestReceived: () => "/mypage/gift/request/received",

  // たいあっぷギフト管理 - リクエスト - 受信したリクエスト詳細
  mypageGiftRequestReceivedDetail: (commissionId: number) =>
    `/mypage/gift/request/received/${commissionId}`,

  // ギフティング規約同意
  mypageGiftTerms: () => "/mypage/gift/terms",

  /**
   * 5系
   */
  // 5-1 ユーザーページ トップ
  userTop: (displayName: string) => `/user/${displayName}`,
  // ユーザーページ - レビュー
  userReview: (displayName: string) => `/user/${displayName}/review`,
  // ユーザーページ - ギフト
  userGift: (displayName: string) => `/user/${displayName}/gift`,
  // ユーザーページ - ギフト送付完了
  userGiftCompleted: (displayName: string) =>
    `/user/${displayName}/gift/completed`,
  // ユーザーページ - リクエスト
  userRequest: (displayName: string) => `/user/${displayName}/request`,
  // ユーザーページ - リクエスト送付完了
  userRequestCompleted: (displayName: string) =>
    `/user/${displayName}/request/completed`,

  /**
   * 6系
   */
  // 6-1 作品詳細
  tieupDetail: (tieupId: number) => `/tieups/${tieupId}`,

  // 作品詳細 - 話一覧
  tieupDetailSections: (tieupId: number) => `/tieups/${tieupId}/sections`,

  // 作品詳細 - 巻一覧
  tieupDetailVolumes: (tieupId: number) => `/tieups/${tieupId}/volumes`,

  // 作品詳細 - 巻まとめて購入
  tieupDetailVolumesMultibuy: (tieupId: number) =>
    `/tieups/${tieupId}/volumes/multibuy`,

  // マンガ作品詳細
  comicsDetail: (comicSeriesId: string | number) => `/comics/${comicSeriesId}`,

  // マンガ作品詳細 - 話一覧
  comicsDetailSections: (comicSeriesId: string | number) =>
    `/comics/${comicSeriesId}/sections`,

  // マンガViewer
  comicsViewer: (comicSeriesId: string | number, sectionId: string | number) =>
    `/comics/${comicSeriesId}/sections/${sectionId}`,

  // マンガ購入内容確認
  comicsSales: (
    comicSeriesId: string | number,
    sectionId: string | number,
    returnUrl?: string | Location,
  ) =>
    returnUrl
      ? `/comics/${comicSeriesId}/sections/${sectionId}/sales?return_url=${returnUrl}`
      : `/comics/${comicSeriesId}/sections/${sectionId}/sales`,

  // マンガ購入完了
  comicsSalesCompleted: (
    comicSeriesId: string | number,
    sectionId: string | number,
  ) => `/comics/${comicSeriesId}/sections/${sectionId}/sales/completed`,

  /**
   * 7系
   */
  // 7-1 サインイン
  authSignIn: () => `/auth/sign-in`,
  // 7-2 サインアップ
  authSignUp: () => `/auth/sign-up`,
  // 7-3 ユーザー情報登録
  authCreatorProfile: () => `/auth/create-profile`,
  // 7-5 パスワードリマインダー
  authPasswordNew: () => `/auth/password-new`,
  // 7-6 ログイン用メールアドレス変更
  authChangeLoginEmail: () => `/auth/change-login-email`,
  // 7-7 パスワード変更
  authChangePassword: () => `/auth/change-password`,
  // 7-10 ログイン用メールアドレス変更
  authChangeNotificationEmail: () => `/auth/change-notification-email`,
  // プライバシー設定
  authPrivacySettings: () => `/auth/privacy-settings`,
  // LINE連携用コールバックページ
  authlineLinkingcAcounts: (state: string, code: string) =>
    `/auth/line-linking-accounts?state=${state}&code=${code}`,
  // LINE連携エラー画面
  authlineLinkingcAcountsError: () => `/auth/line-linking-accounts/error`,
  // LINE連携完了画面
  authlineLinkingcAcountsCompleted: () =>
    `/auth/line-linking-accounts/completed`,

  /**
   * 8系
   */
  // 8-1 通知一覧
  notifications: () => `/notifications`,
  // 8-2 通知詳細
  notification: (notificationId: number) => `/notifications/${notificationId}`,

  /**
   * 9系
   */
  // 9-1 メッセージ一覧
  messages: () => `/messages`,
  // 9-2 メッセージ詳細
  message: (messageId: number) => `/messages/${messageId}`,

  /**
   * 10系
   */
  // 10-1 クリエイタートップ
  creator: () => `/creator`,
  // 10-2 クリエイター検索(小説)
  creatorSearchNovelist: () => `/creator/search/novelist`,
  // 10-3 クリエイター検索（小説家 / ジャンル検索）
  creatorSearchGenres: (type = "novels") =>
    `/creator/search/genres?type=${type}`,
  // 10-4 クリエイター検索 （キャラ検索）
  creatorSearchCharacters: () => `/creator/search/characters`,
  // 10-6 クリエイター検索(イラストレーター)
  creatorSearchIllustrator: () => `/creator/search/illustrator`,
  // 10-7 クリエイター検索 イラスト詳細
  creatorSearchIllustrationsDetail: (illutrationId: number) =>
    `/creator/illustrations/${illutrationId}`,
  // 10-10 クリエイター検索 - 小説詳細 - 作品詳細
  creatorNovelsDetail: (novel_id: number) => `/creator/novels/${novel_id}`,
  // 10-10 クリエイター検索 - 小説詳細 - 目次
  creatorNovelsDetailTabDetail: (novel_id: number) =>
    `/creator/novels/${novel_id}?tab=1`,

  /**
   * ここから ビューワのリンク
   */

  // 10-11 ビューワ（表紙ページ）
  viewerCover: (novelId: number) => `/novels/${novelId}/cover`,
  // 10-11 ビューワ（口絵ページ）
  viewerFrontispiece: (novelId: number, isBackward?: boolean) =>
    isBackward
      ? `/novels/${novelId}/frontispiece?type=${BACKWARD_QUERY_PARAMS}`
      : `/novels/${novelId}/frontispiece`,
  // 10-11 ビューワ（本文のページ）
  viewerText: (novelId: number, chapterId: number, isBackward?: boolean) =>
    isBackward
      ? `/novels/${novelId}/chapters/${chapterId}?type=${BACKWARD_QUERY_PARAMS}`
      : `/novels/${novelId}/chapters/${chapterId}`,

  /**
   * ビューワのリンク ここまで
   */

  // 10-14 共有メモ リスト
  creatorNovelsNotes: (novelId: number) => `/creator/novels/${novelId}/notes`,
  // 10-15 共有メモ 詳細
  creatorNovelsNoteDetail: (novelId: number, noteId: number) =>
    `/creator/novels/${novelId}/notes/${noteId}`,
  // 10-16 キャラリスト リスト
  creatorNovelsCharacters: (novelId: number) =>
    `/creator/novels/${novelId}/characters`,
  // 10-17 キャラリスト 詳細
  creatorNovelsCharacterDetail: (novelId: number, characterId: number) =>
    `/creator/novels/${novelId}/characters/${characterId}`,
  // たいあっぷ一覧
  creatorTieup: () => `/creator/tieup`,

  /**
   * 11系
   */
  // 11-1 クリエイターユーザーページ
  creatorUser: (displayName: string) => `/creator/user/${displayName}`,
  // 11-1 クリエイターユーザーページ（イラストタブを表示）
  creatorUserTabIllust: (displayName: string) =>
    `/creator/user/${displayName}?tab=illust`,

  /**
   * 12系
   */
  // 12-1 クリエイターマイページTOP
  creatorMyPage: () => `/creator/mypage`,
  // 12-1-1 小説一覧
  creatorMyPageNovels: () => `/creator/mypage/novels`,
  // 12-1-2 小説管理
  creatorMyPageNovelsNovel: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}`,
  // 12-4 小説 - 新規作成 (基本情報入力)
  creatorMyPageNovelsCreateNovel: () => `/creator/mypage/novels/new`,
  // 12-1-3 小説 - 基本情報入力 - 編集
  creatorMyPageNovelsNovelDetail: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/edit`,
  // 12-1-6 小説 - あらすじ入力 - 編集
  creatorMyPageNovelsNovelOutline: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/outline`,
  // 12-1-7 小説 - メモ - 一覧
  creatorMyPageNovelsNovelNote: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/notes/`,
  // 12-1-7-1 小説 - メモ - 新規作成
  creatorMyPageNovelsNovelNoteNew: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/notes/new`,
  // 12-1-7-1 小説 - メモ - 詳細
  creatorMyPageNovelsNovelNoteDetail: (novel_id: number, note_id: number) =>
    `/creator/mypage/novels/${novel_id}/notes/${note_id}`,
  // 12-1-7-1 小説 - メモ - 編集
  creatorMyPageNovelsNovelNoteEdit: (novel_id: number, note_id: number) =>
    `/creator/mypage/novels/${novel_id}/notes/${note_id}/edit`,
  // 12-1-8 小説 - キャラリスト - 一覧
  creatorMyPageNovelsNovelCharacters: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/characters`,
  // 12-1-8-1 小説 - キャラリスト - 新規作成
  creatorMyPageNovelsNovelCharactersNew: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/characters/new`,
  // 12-1-8-2 小説 - キャラリスト - 詳細
  creatorMyPageNovelsNovelCharactersDetail: (
    novel_id: number,
    character_id: number,
  ) => `/creator/mypage/novels/${novel_id}/characters/${character_id}`,
  // 12-1-8-2 小説 - キャラリスト - 詳細
  creatorMyPageNovelsNovelCharactersEdit: (
    novel_id: number,
    character_id: number,
  ) => `/creator/mypage/novels/${novel_id}/characters/${character_id}/edit`,
  // 12-1-9 小説 - 公開管理 - 編集
  creatorMyPageNovelsNovelPublicManagement: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/public-management`,
  // 12-1-4 小説 - エディター - chapter 新規作成
  creatorMyPageNovelsNovelNewChapter: (novel_id: number) =>
    `/creator/mypage/novels/${novel_id}/chapters/new`,
  // 12-1-4 小説 - エディター - chapter 新規作成（たいあっぷ対象小説）
  creatorMyPageTieupNovelNewChapter: (tieup_id: number) =>
    `/creator/mypage/tieup/${tieup_id}/chapters/new`,
  // 12-1-4' 小説 - エディター - chapter 編集
  creatorMyPageNovelsNovelEditChapter: (novel_id: number, chapter_id: number) =>
    `/creator/mypage/novels/${novel_id}/chapters/${chapter_id}/edit`,
  // 12-1-4' 小説 - エディター - chapter 編集（たいあっぷ対象小説）
  creatorMyPageTieupNovelEditChapter: (tieup_id: number, chapter_id: number) =>
    `/creator/mypage/tieup/${tieup_id}/chapters/${chapter_id}/edit`,
  // 12-2-1 イラスト一覧
  creatorMyPageIllustrations: () => `/creator/mypage/illustrations`,
  // イラスト新規作成
  creatorMyPageIllustrationNew: () => `/creator/mypage/illustrations/new`,
  // 12-2-2 イラスト編集
  creatorMyPageIllustrationEdit: (illustration_id: number) =>
    `/creator/mypage/illustrations/${illustration_id}`,
  // 12-2-3 イラスト公開管理
  creatorMyPageIllustrationPublish: (illustration_id: number) =>
    `/creator/mypage/illustrations/${illustration_id}/public-management`,
  // 12-6-2 たいあっぷ作品管理
  creatorMyPageTieUp: (tieupId: number) => `/creator/mypage/tieup/${tieupId}`,
  // たいあっぷ申請一覧
  creatorMyPageTieUpRequests: () => `/creator/mypage/tieup-requests`,
  // 12-6-3
  creatorMyPageTieUpData: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/data`,
  // 申請メッセージ
  creatorMyPageTieupRequestMessage: (tieupId: number) =>
    `/creator/mypage/tieup-requests/${tieupId}`,
  // 12-6-4 たいあっぷ作品詳細設定
  creatorMyPageTieupEdit: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/edit`,
  // 12-6-5 たいあっぷ - あらすじ入力 - 編集
  creatorMyPageTieupOutline: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/outline`,
  // 12-6-6 たいあっぷ - メモ - 一覧
  creatorMyPageTieupNote: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/notes/`,
  // 12-6-6 たいあっぷ - メモ - 新規作成
  creatorMyPageTieupNoteNew: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/notes/new`,
  // 12-6-6 たいあっぷ - メモ - 詳細
  creatorMyPageTieupNoteDetail: (tieupId: number, note_id: number) =>
    `/creator/mypage/tieup/${tieupId}/notes/${note_id}`,
  // 12-6-6 たいあっぷ - メモ - 編集
  creatorMyPageTieupNoteEdit: (tieupId: number, note_id: number) =>
    `/creator/mypage/tieup/${tieupId}/notes/${note_id}/edit`,
  // 12-6-7 たいあっぷ - キャラリスト - 一覧
  creatorMyPageTieupCharacters: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/characters`,
  // 12-6-7 たいあっぷ - キャラリスト - 新規作成
  creatorMyPageTieupCharactersNew: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/characters/new`,
  // 12-6-7 たいあっぷ - キャラリスト - 詳細
  creatorMyPageTieupCharactersDetail: (tieupId: number, character_id: number) =>
    `/creator/mypage/tieup/${tieupId}/characters/${character_id}`,
  // 12-6-7 たいあっぷ - キャラリスト - 詳細
  creatorMyPageTieupCharactersEdit: (tieupId: number, character_id: number) =>
    `/creator/mypage/tieup/${tieupId}/characters/${character_id}/edit`,
  // 12-6-7 たいあっぷ - 公開管理 - 編集
  creatorMyPageTieupPublicManagement: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/public-management`,
  // 12-6-2 たいあっぷ作品管理
  creatorMyPageTieup: (tieupId: number) => `/creator/mypage/tieup/${tieupId}`,
  // 12-6-10 シリーズ作品一覧
  creatorMyPageTieupSeries: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/series`,
  // 12-8-1 たいあっぷ申請管理
  creatorMyPageTieupApplications: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/applications`,
  creatorMyPageTieupMembers: (tieupId: number) =>
    `/creator/mypage/tieup/${tieupId}/members`,
  // 12-7 クリエイター情報編集
  creatorMyPageEditProfile: () => `/creator/mypage/edit-profile`,
  // 12-9 通知設定
  creatorMyPageSettingNotifications: () =>
    `/creator/mypage/notification-settings`,

  /**
   * 13系
   */
  // 13-2 チャットルーム
  chatRoom: (chatRoomId: number) => `/messages/${chatRoomId}`,

  /**
   * 14系
   */
  // 14-7 ユーザー退会
  deactivate: () => `/deactivate`,

  /**
   * v2
   */

  // 作品詳細(シリーズ)
  seriesDetail: (seriesId: string) => `/series/${seriesId}`,

  // 作品詳細(シリーズ) 巻タブ
  seriesDetailVolumes: (seriesId: string) => `/series/${seriesId}/volumes`,

  // 話一覧(シリーズ)
  seriesAllSections: (seriesId: string) => `/series/${seriesId}/all-sections`,

  // 話 viewer 表紙
  seriesSectionsViewerCover: (seriesId: string) =>
    `/viewer/series/${seriesId}/sections/cover`,

  // 話 viewer 口絵
  seriesSectionsViewerFrontispiece: (seriesId: string, isBackward?: boolean) =>
    isBackward
      ? `/viewer/series/${seriesId}/sections/frontispiece?type=${BACKWARD_QUERY_PARAMS}`
      : `/viewer/series/${seriesId}/sections/frontispiece`,

  // 話 viewer
  seriesSectionsViewer: (
    seriesId: string,
    sectionId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/viewer/series/${seriesId}/sections/${sectionId}?type=${BACKWARD_QUERY_PARAMS}`
      : `/viewer/series/${seriesId}/sections/${sectionId}`,

  // 話 viewer 表紙
  seriesAdminSectionsViewerCover: (seriesId: string) =>
    `/admin/viewer/series/${seriesId}/sections/cover`,

  // 話 viewer 口絵
  seriesAdminSectionsViewerFrontispiece: (
    seriesId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/admin/viewer/series/${seriesId}/sections/frontispiece?type=${BACKWARD_QUERY_PARAMS}`
      : `/admin/viewer/series/${seriesId}/sections/frontispiece`,

  // 話 viewer
  seriesAdminSectionsViewer: (
    seriesId: string,
    sectionId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/admin/viewer/series/${seriesId}/sections/${sectionId}?type=${BACKWARD_QUERY_PARAMS}`
      : `/admin/viewer/series/${seriesId}/sections/${sectionId}`,

  // 話 コメント一覧
  seriesSectionsComments: (seriesId: string, sectionId: string) =>
    `/series/${seriesId}/sections/${sectionId}/comments`,

  // 話 コメント投稿
  seriesSectionsCommentsNew: (seriesId: string, sectionId: string) =>
    `/series/${seriesId}/sections/${sectionId}/comments/new`,

  // 巻一覧(シリーズ)
  seriesAllVolumes: (seriesId: string) => `/series/${seriesId}/all-volumes`,

  // 巻 viewer 表紙
  seriesVolumesViewerCover: (seriesId: string, volumeId: string) =>
    `/viewer/series/${seriesId}/volumes/${volumeId}/cover`,

  // 巻 viewer 口絵
  seriesVolumesViewerFrontispiece: (
    seriesId: string,
    volumeId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/viewer/series/${seriesId}/volumes/${volumeId}/frontispiece?type=${BACKWARD_QUERY_PARAMS}`
      : `/viewer/series/${seriesId}/volumes/${volumeId}/frontispiece`,

  // 巻 viewer
  seriesVolumesViewer: (
    seriesId: string,
    volumeId: string,
    sectionId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/viewer/series/${seriesId}/volumes/${volumeId}/${sectionId}?type=${BACKWARD_QUERY_PARAMS}`
      : `/viewer/series/${seriesId}/volumes/${volumeId}/${sectionId}`,

  // 巻 viewer 表紙
  seriesAdminVolumesViewerCover: (seriesId: string, volumeId: string) =>
    `/admin/viewer/series/${seriesId}/volumes/${volumeId}/cover`,

  // 巻 viewer 口絵
  seriesAdminVolumesViewerFrontispiece: (
    seriesId: string,
    volumeId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/admin/viewer/series/${seriesId}/volumes/${volumeId}/frontispiece?type=${BACKWARD_QUERY_PARAMS}`
      : `/admin/viewer/series/${seriesId}/volumes/${volumeId}/frontispiece`,

  // 巻 viewer
  seriesAdminVolumesViewer: (
    seriesId: string,
    volumeId: string,
    sectionId: string,
    isBackward?: boolean,
  ) =>
    isBackward
      ? `/admin/viewer/series/${seriesId}/volumes/${volumeId}/${sectionId}?type=${BACKWARD_QUERY_PARAMS}`
      : `/admin/viewer/series/${seriesId}/volumes/${volumeId}/${sectionId}`,

  // 巻 コメント一覧
  seriesVolumesComments: (seriesId: string, volumeId: string) =>
    `/series/${seriesId}/volumes/${volumeId}/comments`,

  // 巻 コメント投稿
  seriesVolumesCommentsNew: (seriesId: string, volumeId: string) =>
    `/series/${seriesId}/volumes/${volumeId}/comments/new`,

  /**
   * v2 creator
   */
  creatorMypageSeriesDetail: (series_id: string) =>
    `/creator/mypage/series/${series_id}`,

  creatorMypageSeriesSetting: (series_id: string) =>
    `/creator/mypage/series/${series_id}/setting`,

  creatorMypageSeriesSectionsOrder: (series_id: string) =>
    `/creator/mypage/series/${series_id}/sections/order`,

  creatorMypageSeriesSectionsThumbnail: (series_id: string) =>
    `/creator/mypage/series/${series_id}/sections/thumbnail`,

  creatorMypageSeriesSectionsPublish: (series_id: string) =>
    `/creator/mypage/series/${series_id}/sections/publish`,

  creatorMypageSeriesSectionsNew: (series_id: string) =>
    `/creator/mypage/series/${series_id}/sections/new`,

  creatorMypageSeriesVolumes: (series_id: string) =>
    `/creator/mypage/series/${series_id}/volumes`,

  creatorMypageSeriesNewVolume: (series_id: string) =>
    `/creator/mypage/series/${series_id}/volumes/new`,

  creatorMypageSeriesVolumeEdit: (series_id: string, volume_id: string) =>
    `/creator/mypage/series/${series_id}/volumes/${volume_id}/edit`,

  creatorMypageSeriesVolumesPublish: (series_id: string) =>
    `/creator/mypage/series/${series_id}/volumes/publish`,

  creatorMypageSeriesPartners: (series_id: string) =>
    `/creator/mypage/series/${series_id}/partners`,

  creatorMypageSeriesMemo: (series_id: string) =>
    `/creator/mypage/series/${series_id}/memo`,

  creatorMypageSeriesMemoNew: (series_id: string) =>
    `/creator/mypage/series/${series_id}/memo/new`,

  creatorMypageSeriesMemoEdit: (series_id: string, memo_id: string) =>
    `/creator/mypage/series/${series_id}/memo/${memo_id}/edit`,

  creatorMypageSeriesCharacter: (series_id: string) =>
    `/creator/mypage/series/${series_id}/character`,

  creatorMypageSeriesCharacterNew: (series_id: string) =>
    `/creator/mypage/series/${series_id}/character/new`,

  creatorMypageSeriesCharacterEdit: (series_id: string, character_id: string) =>
    `/creator/mypage/series/${series_id}/character/${character_id}/edit`,

  /**
   * 作品購入系
   */
  // 6-2 作品販売(購入前)
  seriesSales: (series_id: string, tieup_id: string) =>
    `/series/${series_id}/sales/${tieup_id}`,
  // 6-3 作品販売(購入完了)
  seriesSalesCompleted: (series_id: string, tieup_id: string) =>
    `/series/${series_id}/sales/${tieup_id}/completed`,
  // 6-4 作品販売(エラー)
  seriesSalesError: (series_id: string, tieup_id: string) =>
    `/series/${series_id}/sales/${tieup_id}/error`,
}

export type RouteKeys = keyof typeof routes

export default routes
