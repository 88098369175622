import styles from "./FullScreenLoading.module.scss"
import { BarLoader } from "react-spinners"
import { useContext, useEffect } from "react"
import { globalStoreContext } from "../../../store/GlobalStore"
import { useUnmount } from "react-use"
import colors from "../../../config/color"

const FullScreenLoading: React.FC = () => {
  const {
    globalStore: {
      loading: { fullScreenLoading },
    },
    globalDispatch,
  } = useContext(globalStoreContext)

  useEffect(() => {
    globalDispatch({
      type: "scrollLock",
      payload: fullScreenLoading,
    })
  }, [globalDispatch, fullScreenLoading])

  useUnmount(() => {
    globalDispatch({ type: "scrollLock", payload: false })
  })

  if (!fullScreenLoading) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <BarLoader color={colors.spectrum.tieupTheme} />
    </div>
  )
}

export default FullScreenLoading
