import Link from "next/link"
import React, { useCallback, useContext } from "react"
import { animateScroll as scroll } from "react-scroll"
import routes from "routes"
import { globalStoreContext } from "src/store/GlobalStore"
import URLUtils from "src/utils/URLUtils"
import TieupImg from "../TieupImg"
import TieupLogo from "../TieupLogo"

const CommonFooter = () => {
  return (
    <div className="global-footer-common">
      <nav className="global-footer-policy">
        <ul>
          <li>
            <a
              href={URLUtils.getHelpPage("personalinfo/index.html")}
              target="_blank"
              rel="noreferrer"
            >
              個人情報のお取り扱いについて
            </a>
          </li>
          <li className="--size-large">
            <a
              href="https://www.trenders.co.jp/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>
          </li>
          <li className="--size-large">
            <a
              href={URLUtils.getHelpPage("legal/commerce-law.pdf")}
              target="_blank"
              rel="noreferrer"
            >
              特定商取引法に基づく表示
              <img
                className="global-footer-policy-pdf-icon"
                src="/img/icn_pdf.png"
                alt="PDFアイコン"
              />
            </a>
          </li>
          <li className="--size-large">
            <a
              href={URLUtils.getHelpPage("legal/ebook-purchase-terms.pdf")}
              target="_blank"
              rel="noreferrer"
            >
              たいあっぷ商品購入規約
              <img
                className="global-footer-policy-pdf-icon"
                src="/img/icn_pdf.png"
                alt="PDFアイコン"
              />
            </a>
          </li>
        </ul>
      </nav>
      <div className="global-footer-copyright">
        <TieupLogo />
        <small>©Tieup All rights reserved.</small>
      </div>
    </div>
  )
}

const GlobalFooter: React.FC = () => {
  const {
    globalStore: {
      user: { apiUserData },
    },
  } = useContext(globalStoreContext)

  const onScrollTop = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    scroll.scrollToTop({ duration: 1000, smooth: "easeInOutQuint" })
  }, [])

  return (
    <footer className="global-footer">
      <div className="global-footer-pagetop">
        <a
          className="global-footer-pagetop-anchor"
          href="#top"
          onClick={onScrollTop}
        >
          Page Top
        </a>
      </div>
      <div className="global-footer-inner">
        <div className="global-footer-inner-upper">
          <nav className="global-footer-guid">
            <ul>
              <li>
                <a
                  href={URLUtils.getHelpPage("info/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  運営からのお知らせ
                </a>
              </li>
              <li>
                <a
                  href={URLUtils.getHelpPage("terms/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                </a>
              </li>
              <li>
                <a
                  href={URLUtils.getHelpPage("guide/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  ガイドライン
                </a>
              </li>
              <li>
                <a
                  href={URLUtils.getHelpPage("qa/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  よくある質問
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a
                  href={URLUtils.getHelpPage("environment/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  推奨端末・ブラウザ
                </a>
              </li>
              <li>
                <a
                  href="https://www.trenders.co.jp/aboutus/info/"
                  target="_blank"
                  rel="noreferrer"
                >
                  サイト運営者情報
                </a>
              </li>
              <li className="--size-large">
                <a
                  href={URLUtils.getHelpPage("contact/index.html")}
                  target="_blank"
                  rel="noreferrer"
                >
                  お問い合わせ
                </a>
              </li>
              {apiUserData?.display_name && (
                <li className="--size-large">
                  <Link href={routes.deactivate()}>
                    <a>ユーザー退会</a>
                  </Link>
                </li>
              )}
            </ul>
          </nav>

          <div className="global-footer-banners">
            <a
              href="https://note.com/tieupnovels"
              target="_blank"
              rel="noreferrer"
            >
              <TieupImg
                src={"/img/bnr_note.jpg"}
                alt="たいあっぷ運営が語る。 note公開中"
              />
            </a>
          </div>
        </div>

        <hr className="global-footer-horizon" />
        <CommonFooter />
      </div>
    </footer>
  )
}

export default GlobalFooter
