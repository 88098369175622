const TieupLogo: React.FC = () => {
  return (
    <svg viewBox="0 0 627.32 100">
      <title>たいあっぷ</title>
      <g>
        <g>
          <path
            d="M593.23,30.13c3.23-1.96,5.39-2.84,7.94-3.33l-0.96-9.7c-0.78,0.1-1.37,0.1-1.76,0.1
    c-5.69,0-11.95-0.87-19.21-2.92c-5.88-1.67-10.5-3.94-13.05-5.51l-4.06,7.96c6.87,4.72,16.64,8.13,25.12,8.4
    c-8.63,5.29-13.72,11.87-13.72,18.53c0,4.31,1.76,8.04,6.67,13.43c4.8,5.49,5.39,6.17,6.96,8.82c1.47,2.55,2.16,4.61,2.16,6.67
    c0,4.51-3.63,7.74-8.72,7.74c-5.68,0-11.37-2.75-17.05-8.23c-0.2,1.01-2.88,8.01-3.33,9.16c6.67,5.39,13.92,8.58,20.98,8.58
    c5.1,0,9.79-1.47,13.13-4.22c3.82-3.14,5.69-7.94,5.69-12.84c0-5.78-2.16-10.78-8.53-17.64c-6.37-6.86-6.76-6.86-7.84-8.72
    c-0.59-0.98-0.98-2.65-0.98-3.63C582.64,38.76,586.27,34.44,593.23,30.13z"
          />
          <path
            d="M616,43.21c5.68,0,10.29-4.61,10.29-10.39c0-5.68-4.61-10.29-10.29-10.29c-5.78,0-10.39,4.61-10.39,10.29
    C605.61,38.51,610.31,43.21,616,43.21z M616,27.14c3.14,0,5.68,2.55,5.68,5.68c0,3.24-2.55,5.78-5.68,5.78
    c-3.24,0-5.78-2.55-5.78-5.78C610.21,29.68,612.76,27.14,616,27.14z"
          />
          <path
            d="M292.51,73.26c-1.96,0.21-7.04-2.83-9.05-21.97c-1.13-10.8-0.77-19.78-0.25-25.29l-12.26-2.6
    c-0.3,6.8-0.09,13.03,0.89,25.83c1.43,18.69,6.3,29.8,13.86,35.75c1.83,1.4,4.46,2.81,7.6,2.48c7.95-0.83,11.76-10.97,15.95-23.12
    l-8.9-7.19C298.97,62.06,296.04,72.89,292.51,73.26z"
          />
          <path
            d="M325.19,31.44c5.13,7.89,11.64,19.54,14.51,40.06l12.23-3.45c-2.42-15.92-7.69-27.18-16.57-41.55
    L325.19,31.44z"
          />
          <path
            d="M504.05,39.6c-13.06-0.01-35.3,5.79-45.29,8.01l4,10.12c10.23-2.98,17.21-4.78,19.36-5.28
    c5.48-1.36,14.44-3.89,19.18-3.61c5.48,0.33,15.02,3.55,14.34,14.83c-0.96,15.91-21.25,18.5-29.99,17.97
    c-1.69-0.1-3.79-0.34-7.67-0.89l4,9.49c14.17,2.43,44.07-1.61,45.5-25.32C528.49,48.17,517.14,39.61,504.05,39.6z"
          />
          <path
            d="M433.06,43.98c-2.57-1.17-5.24-2.11-7.82-2.85c0.38-1.15,0.48-1.15,1.15-3.16c1.05-2.68,1.05-2.68,1.43-3.54
    l-9.09-2.58c-0.29,2.3-0.96,4.78-2.1,7.75c-2.49-0.09-3.63-0.09-5.36-0.09c-4.3,0-7.39,0.36-12.01,1.31
    c0.47-4.28,1.16-8.41,1.93-12.17c12.43-0.27,25.51-0.69,32.51-1.25l-0.09-9.5c-8.27,0.62-19.02,1.11-30.21,1.44
    c1.46-5.41,2.68-8.84,2.68-8.84l-10.84-2.74c-0.78,2.97-1.72,6.32-2.75,11.85c-7.04,0.13-14,0.2-20.39,0.18l0.41,9.19
    c3.11,0.04,10.15-0.01,18.52-0.14c-0.52,3.86-1.06,8.44-1.59,13.94c-0.03,0.27-0.04,0.53-0.07,0.79
    c-5.24,2.25-8.99,4.25-12.64,7.81c-6.03,5.93-9,12.54-9,20.19c0,9.18,4.99,16.16,12.93,16.16c6.98,0,14.91-2.88,22.56-10.25
    c7.94-7.65,13.49-15.88,19.13-28.7c8.32,2.58,12.91,7.75,12.91,14.63c0,7.94-5.74,14.25-16.07,17.79
    c-4.5,1.43-8.23,2.76-14.06,2.95c2.85,4.95,3.4,6.13,4.3,8.33c8.42-0.96,15.38-2.39,21.02-5.26c10.43-5.26,15.88-13.2,15.88-23.24
    c0-6.22-2.08-11.3-6.3-15.4C438.04,46.63,435.61,45.14,433.06,43.98z M376.65,71.92c0-4.3,1.72-9.08,4.97-13.1
    c1.97-2.48,4.03-4.51,7.39-6.69c0,9.81,1.46,18.06,2.82,23.58c-3.35,2.65-6.26,3.85-9.26,3.85
    C379.04,79.57,376.65,76.51,376.65,71.92z M401.23,67.81c-0.31,0.4-0.48,0.62-0.66,0.84c-1.08-5.12-2.07-12.16-1.85-20.21
    c3.87-1.39,7.84-2.05,12.74-2.05c0.96,0,2.09,0,3.24,0.19C411.26,54.43,406.88,61.11,401.23,67.81z"
          />
          <path
            d="M235.44,81.47c-2.39,0-10.29-0.1-13.21-2.65c-1.04-0.88-1.25-1.67-1.25-2.94c0-0.2,0-1.76,0.42-3.63
    l-10.29-2.06c-1.25,3.43-1.25,6.57-1.25,7.06c0,10,8.01,14.9,26.72,14.9c7.28,0,12.89-0.59,18.2-1.47l-1.04-11.37
    C250.73,80,245.11,81.47,235.44,81.47z"
          />
          <path
            d="M217.74,50.75c0,0,19.44-1.17,25.3-0.69c4.24,0.34,8.97,1.29,8.97,1.29l-0.19-11.17c0,0-3.48-1.22-21.42-0.11
    c-5.05,0.31-12.49,1.16-12.49,1.16L217.74,50.75z"
          />
          <path
            d="M554.76,52.54c-4.08,10.78-14.56,24.22-17.1,27.41l10.57,5.29c4.58-7.16,6.1-10.47,13.45-28.34L554.76,52.54z
    "
          />
          <path d="M609.78,49.73l-6.7,3.99c5.78,7.12,8.09,9.97,14,28.45l10.24-3.61C625.61,70.51,618.06,57.12,609.78,49.73z" />
          <path
            d="M228.67,31.18l-0.48-10.03c-7.23,1.27-13.99,2.12-20,2.68c2.11-11.45,2.27-14.63,2.27-14.63l-10.63-1.43
    c0,0-0.64,6.5-2.82,16.85c-9.28,0.45-14.99,0.18-14.99,0.18l0.28,10.2c4.18,0.03,8.23-0.02,12.32-0.18
    c-2.35,9.18-5.69,20.04-10.39,31.51c-4.82,11.76-10.55,20.81-10.55,20.81l11.17,5.34c0,0,13.2-20.21,21.32-58.38
    C212.99,33.51,220.27,32.58,228.67,31.18z"
          />
        </g>
        <polygon
          points="144.75,5.46 103.64,19.63 101.97,31.12 128.89,21.84 118.14,56.6 78.58,71.02 92.14,0 81.08,2.96
  72.37,48.55 63.67,2.96 52.6,0 66.16,71.02 26.6,56.6 15.86,21.84 42.78,31.12 41.1,19.63 0,5.46 18.26,64.54 57.31,78.78
  35.87,86.59 32.68,76.27 20.88,73.03 29.22,100 72.37,84.27 115.53,100 123.83,73.12 112.06,76.27 108.87,86.59 87.43,78.78
  126.49,64.54 	"
        />
      </g>
    </svg>
  )
}

export default TieupLogo
