/**
 * Firebase Auth からユーザー情報を取得
 */
import { getAuth, onAuthStateChanged, User } from "firebase/auth"
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging"
import React, { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/nextjs"
import { get } from "lodash"

import { refreshToken } from "../utils/auth/idToken"
import { globalStoreContext } from "../store/GlobalStore"
import { FirebaseUserData, Providers } from "../../types"
import {
  getFirebaseTokenFromCookie,
  removeFirebaseUserCookie,
  setFirebaseUserCookie,
} from "../utils/auth/userCookies"
import { useApiUserData } from "./hooks/useApiUserData"
import ApiUserData from "./ApiUserData"
import { useFCM } from "./hooks/useFCM"
import { useLoading } from "./hooks/useLoading"
import { useTieupToast } from "./hooks/useTieupToast"
import routes from "routes"
import { registerUser } from "../models/user/registerUser"

// 空のユーザ
export const emptyUserData = {
  id: null,
  email: null,
  displayName: null,
  photoURL: null,
  providers: null,
}

/** Firebase Userをマップ */
const mapUserData = (user: User): FirebaseUserData => {
  const providerData = user.providerData
  let photoURL: string | null = null

  for (const provider of providerData) {
    const photo = get(provider, "photoURL")
    if (photo) {
      photoURL = photo
      break
    }
  }

  const providers = providerData.map((p) => p?.providerId) as Providers[]

  const { uid, email, displayName } = user

  return {
    id: uid,
    email,
    displayName,
    photoURL,
    providers,
  }
}

const UserData: React.FC = ({ children }) => {
  const {
    globalDispatch,
    globalStore: {
      user: { firebaseUserData },
    },
  } = useContext(globalStoreContext)
  const accessToken = getFirebaseTokenFromCookie()

  const { updateApiUserData } = useApiUserData()

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [fcmToken, setFcmToke] = useState("")

  const { mutateFCM } = useFCM()

  const { fullScreenLoading } = useLoading()

  const { addToastError } = useTieupToast()

  const router = useRouter()

  useEffect(() => {
    /**
     * Firebaseのユーザー情報の変更
     */
    const auth = getAuth()
    onAuthStateChanged(auth, async (user) => {
      try {
        // 匿名認証の実行は削除済: https://github.com/trenders/prj-tieup-front/pull/1520
        // もし匿名認証の実行処理を削除する以前に匿名ログインしていた場合、匿名アカウントの情報は不要なので Cookie や globalStire の値をリセットする
        if (!user || user.isAnonymous) {
          // リセット
          removeFirebaseUserCookie()
          globalDispatch({
            type: "user/resetUserData",
          })

          return
        }

        fullScreenLoading(true)
        // トークンの強制リフレッシュ
        await refreshToken(true)

        // ユーザー情報をマップ化
        const mappedUser = mapUserData(user)

        // グローバル登録
        globalDispatch({
          type: "user/firebaseUserData",
          payload: mappedUser,
        })

        // クッキーに登録
        setFirebaseUserCookie(mappedUser)

        // 通知用の認可確認
        if (await isSupported()) {
          const permission = await Notification.requestPermission()
          // 通知用のトークン取得
          if (permission === "granted") {
            const token = await getToken(getMessaging())
            setFcmToke(token)
          }
        }

        setIsLoggedIn(true)

        // Sentry の config にユーザー情報をセット
        // @see: https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/identify-user/
        Sentry.configureScope((scope) => {
          scope.setUser({
            id: user.uid ?? "",
          })
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      } finally {
        fullScreenLoading(false)
      }
    })

    /** FCMイベントをリッスン */
    ;(async () => {
      if (await isSupported()) {
        const messaging = getMessaging()
        if (messaging) {
          globalDispatch({ type: "enableFCM", payload: true })
        }
        onMessage(messaging, () => {
          /** 通知があったらAPIをmutateする */
          mutateFCM()
        })
      }
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** 画面のリロード、リフレッシュ時にユーザー情報をグローバルストアおよびバックエンドAPIに登録する処理 */
  useEffect(() => {
    ;(async function () {
      // 新規会員登録ページでは別途同様の処理を行っているのでスルーさせる
      if (
        firebaseUserData === emptyUserData ||
        router.pathname === "/auth/create-profile"
      )
        return

      if (firebaseUserData && accessToken) {
        try {
          fullScreenLoading(true)
          /** ユーザー登録APIを念のために実行しておく */
          await registerUser()
          await updateApiUserData()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)

          addToastError(
            "エラーが発生しました。一度ログアウトし、再度ログインをお試しください。",
          )
          router.push(routes.home())
        } finally {
          fullScreenLoading(false)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUserData, accessToken])

  return (
    <ApiUserData isFirebaseLoggedIn={isLoggedIn} fcmToken={fcmToken}>
      {children}
    </ApiUserData>
  )
}

export default UserData
