import { AxiosError } from "axios"
import * as Sentry from "@sentry/nextjs"

export const handleHttpError = async (error: AxiosError | never) => {
  if (!("isAxiosError" in error)) {
    throw error
  }

  if (!error.response) {
    throw error
  }

  // 5xx系のエラーはSentryにログ送付
  if (
    error.response.status === 500 ||
    error.response.status === 502 ||
    error.response.status === 503
  ) {
    Sentry.captureException(error)
    if (process.env.NEXT_PUBLIC_ENV !== "local") {
      await Sentry.flush(2000)
    }
  }

  if (error.response.status === 403) {
    alert("セッションが期限切れです。ブラウザをリロードしてください。")
    throw new Error(error.toString())
  }

  throw error
}
