import { BaseInputModel } from "../models/http/InputModel/BaseInputModel"

export const makeApiUrlPath = (
  endpointUrl: string,
  inputModel: BaseInputModel,
): string => {
  return Object.entries(inputModel.pathParams).reduce(
    (output, [key, value]) => {
      return output.replace(`{${key}}`, value)
    },
    endpointUrl,
  )
}
