export const serviceWorkerRegister = (scriptURL: string) => {
  if ("serviceWorker" in navigator) {
    // eslint-disable-next-line no-console
    navigator.serviceWorker
      .register(scriptURL)
      // eslint-disable-next-line no-console
      .catch((err) => console.error("Service worker registration failed", err))
  } else {
    // eslint-disable-next-line no-console
    console.log("Service worker not supported")
  }
}

export const serviceWorkerUnRegister = () => {
  if ("serviceWorker" in navigator) {
    // eslint-disable-next-line no-console
    console.log("serviceWorkerUnRegister")
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
}
