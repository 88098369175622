import { emptyUserData } from "src/components/FirebaseUserData"
import { parseCookies, setCookie } from "nookies"
import { FirebaseUserData } from "../../../types"

const maxAge = 2147483647

const FIREBASE_USER_COOKIE_KEY = "firebase_user"
const FIREBASE_ACCESSTOKEN_COOKIE_KEY = "firebaseAccessToken"
const FIREBASE_ACCESSTIMER_COOKIE_KEY = "firebaseAccessTimer"
const TERMS_AGREEMENT = "terms_agreement"

export const setFirebaseUserCookie = (user: FirebaseUserData) => {
  setCookie(null, FIREBASE_USER_COOKIE_KEY, JSON.stringify(user), {
    maxAge,
    path: "/",
    // iOS SafariでsameSiteオプションがついているとcookieの処理がされなくなる問題のためにコメントアウト
    // sameSite: "Strict",
  })
}

/**
 * idTokenと時間（ミリ秒）をcookieに保存する
 * MAX_AGEは設定しない
 * @param idToken
 */
export const setFirebaseTokenToCookie = (idToken: string) => {
  setCookie(null, FIREBASE_ACCESSTOKEN_COOKIE_KEY, idToken, {
    path: "/",
    // sameSite: "Strict",
  })
  setCookie(null, FIREBASE_ACCESSTIMER_COOKIE_KEY, Date.now() + "", {
    path: "/",
    // sameSite: "Strict",
  })
}

export const getFirebaseUserFromCookie = () => {
  const user = parseCookies()[FIREBASE_USER_COOKIE_KEY]
  if (!user) {
    return emptyUserData
  }

  return JSON.parse(user)
}

export const getFirebaseTokenFromCookie = () => {
  const accessToken = parseCookies()[FIREBASE_ACCESSTOKEN_COOKIE_KEY]
  if (!accessToken) {
    return null
  }

  return accessToken
}

export const getFirebaseAccessTimerFromCookie = () => {
  const time = parseCookies()[FIREBASE_ACCESSTIMER_COOKIE_KEY]

  return parseInt(time, 10)
}

// Cookie が空かどうか
export const isEmptyCookie = () => {
  const accessTimer = getFirebaseAccessTimerFromCookie()
  const accessToken = getFirebaseTokenFromCookie()
  const user = getFirebaseUserFromCookie()

  if (user === emptyUserData && !accessToken && !accessTimer) {
    return true
  } else {
    return false
  }
}

export const removeFirebaseUserCookie = () => {
  setCookie(null, FIREBASE_USER_COOKIE_KEY, "", {
    maxAge,
    path: "/",
    sameSite: "strict",
  })

  setCookie(null, FIREBASE_ACCESSTOKEN_COOKIE_KEY, "", {
    maxAge,
    path: "/",
    sameSite: "strict",
  })

  setCookie(null, FIREBASE_ACCESSTIMER_COOKIE_KEY, "", {
    maxAge,
    path: "/",
    sameSite: "strict",
  })
}

export const setTermsAgreementCookie = () => {
  setCookie(null, TERMS_AGREEMENT, "true", {
    maxAge,
    path: "/",
    // sameSite: "Strict",
  })
}

export const getTermsAgreementCookie = () => {
  return parseCookies()[TERMS_AGREEMENT]
}
