import { useRouter } from "next/router"
import { useEffect } from "react"
import { useFCM } from "./hooks/useFCM"
import { useTieupToast } from "./hooks/useTieupToast"

const FCMNotification: React.FC = () => {
  const { firstUnread } = useFCM()
  const { addToastInfo } = useTieupToast()

  const router = useRouter()

  useEffect(() => {
    if (!firstUnread) return
    if (router.pathname === "/notifications") return

    addToastInfo(<a href="/notifications">{firstUnread.title}</a>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstUnread, router.pathname])

  return <></>
}

export default FCMNotification
