import { getFirebaseUserAsync, refreshToken } from "../../../utils/auth/idToken"
import { getFirebaseTokenFromCookie } from "../../../utils/auth/userCookies"
import { User } from "firebase/auth"

export const getTokenHeader = async (): Promise<{
  authorization?: string
}> => {
  await refreshToken()

  // Cookie からトークンを取得
  const idToken = getFirebaseTokenFromCookie()
  const currentUser = (await getFirebaseUserAsync()) as User

  // 会員登録完了前など、Cookie から取れない場合は Firebase の currentUser を直接参照する
  const token =
    !idToken && currentUser ? await currentUser.getIdToken(true) : idToken

  return token ? { authorization: `Bearer ${token}` } : {}
}
