/**
 * URLUtil
 *
 * URL操作のユーティリティ
 */
export default class URLUtil {
  /**
   * マップをクエリー引数に変換する
   * @param {object} map
   */
  static mapToQuery(map: { [key: string]: string | number | boolean }) {
    return Object.keys(map)
      .map((key) => key + "=" + encodeURIComponent(map[key]))
      .join("&")
  }

  /**
   * クエリ文字列を解析する
   * @param {string} query
   */
  static parseQuery(query: string) {
    const parameters: { [key: string]: string } = {}
    const params = query.replace(/^\?/, "").split("&")
    params.forEach((p) => {
      const param = p.split("=")
      const key = param[0]
      const value = decodeURIComponent(param[1])
      parameters[key] = value
    })

    return parameters
  }

  /**
   * GETパラメータを取得する
   */
  static getParameters() {
    return this.parseQuery(location.search)
  }

  /**
   * ルートURLを取得する
   */
  static getRootURL() {
    if (location.origin) {
      return location.origin
    }

    return (
      location.protocol +
      "//" +
      location.hostname +
      (location.port != "80" ? ":" + location.port : "")
    )
  }

  /**
   * 環境変数を元にルートURLを取得する
   */
  static getRootURLFromEnv() {
    switch (process.env.NEXT_PUBLIC_ENV) {
      case "local":
        return "http://localhost:3000"
      case "staging":
        return "https://staging.tieupnovels.com"
      case "release":
        return "https://release.tieupnovels.com"
      case "production":
        return "https://tieupnovels.com"
      default:
        return "https://tieupnovels.com"
    }
  }

  static getStaticPage(path: string) {
    return `${process.env.NEXT_PUBLIC_STATIC_URL}${path}`
  }

  static getHelpPage(path: string) {
    return `${process.env.NEXT_PUBLIC_HELP_PAGE_URL}${path}`
  }

  static getCreatorTieupNovelsURL() {
    switch (process.env.NEXT_PUBLIC_ENV) {
      case "local":
        return "https://staging-creator.tieupnovels.com/"
      case "staging":
        return "https://staging-creator.tieupnovels.com/"
      case "release":
        return "https://release-creator.tieupnovels.com/"
      case "production":
        return "https://creator.tieupnovels.com/"
      default:
        return "https://creator.tieupnovels.com/"
    }
  }

  /**
   * 特定のGETパラメータを削除する
   * @param  {...string} removeParameters
   */
  static removeQueryParameters(...removeParameters: string[]) {
    const parameters = this.getParameters()
    for (let i = 0; i < removeParameters.length; i++) {
      delete parameters[removeParameters[i]]
    }
    const otherParams: string[] = []
    Object.keys(parameters).forEach((key) => {
      if (key) {
        const param = parameters[key]
        otherParams.push(param + "=" + encodeURIComponent(parameters[key]))
      }
    })
    let otherParamsString = otherParams.join("&")
    if (otherParamsString) {
      otherParamsString = "?" + otherParamsString
    }
    // URLを書き換える
    try {
      history.replaceState("", "", location.pathname + otherParamsString)
    } catch (error) {
      try {
        location.href = location.pathname + otherParamsString
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
  }
}
