import { useContext } from "react"
import ReactScrollLock from "react-scrolllock"
import { globalStoreContext } from "../../store/GlobalStore"

const ScrollLock: React.FC = () => {
  const { globalStore } = useContext(globalStoreContext)

  return <ReactScrollLock isActive={globalStore.scrollLock} />
}

export default ScrollLock
