import { v4 as uuidV4 } from "uuid"

import { BasePostInputModel } from "../../models/http/InputModel/BasePostInputModel"
import { makeApiUrlPath } from "../makeApiUrlPath"
import { http } from "./http"
import { getTokenHeader } from "./lib/get-token-header"
import { handleHttpError } from "./lib/handle-http-error"

export const httpPost = async <T>(
  endpointPath: string,
  model: BasePostInputModel,
): Promise<T> => {
  const path = makeApiUrlPath(endpointPath, model)
  const tokenHeader = await getTokenHeader()

  const headers = {
    ...tokenHeader,
    ...model.headers,
    "x-tieup-request-id": uuidV4(),
  }

  return http
    .post<T>(path, model.body, { headers })
    .then((res) => res.data)
    .catch(handleHttpError)
}
