import { NotificationGetInputModel } from "./GetInputModel"
import { NotificationGetResponse } from "./GetResponse"
import { httpGet } from "../../../libs/http/httpGet"

const endpointUrl = "/v1/notification"

export const getNotification = (
  model: NotificationGetInputModel,
): Promise<NotificationGetResponse> => {
  return httpGet<NotificationGetResponse>(endpointUrl, model)
}
