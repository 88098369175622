import { getAuth, User } from "firebase/auth"
import {
  getFirebaseAccessTimerFromCookie,
  getFirebaseTokenFromCookie,
  setFirebaseTokenToCookie,
} from "./userCookies"

// tokenのリフレッシュ期限(10分)
const LIMIT_TIME_MS = 600000

export const getFirebaseUserAsync: () => Promise<User | ""> = () => {
  return new Promise((resolve) => {
    const currentUser = getAuth().currentUser
    if (currentUser) {
      resolve(currentUser)
    } else {
      resolve("")
    }
  })
}

/**
 * idTokenをリフレッシュする
 * @param force 強制実行
 */
export const refreshToken: (force?: boolean) => Promise<string | ""> = (
  force = false,
) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      const currentIdToken = getFirebaseTokenFromCookie()
      let idToken = currentIdToken ? currentIdToken : ""
      const currentUser = await getFirebaseUserAsync()

      if (currentUser) {
        if (force) {
          /** 強制実行 */
          idToken = await currentUser.getIdToken(true)
        } else {
          /** 前回アクセス時間からn分以上経っていたらリフレッシュ */
          const lastAccessTime = getFirebaseAccessTimerFromCookie()
          const now = Date.now()
          if (now - lastAccessTime > LIMIT_TIME_MS) {
            idToken = await currentUser.getIdToken(true)
          }
        }
      }

      if (!currentIdToken || currentIdToken !== idToken) {
        // idTokenをcookieに保存
        setFirebaseTokenToCookie(idToken)
      }

      resolve(idToken)
    } catch (error) {
      resolve("")
    }
  })
}
