export const initAPIMockOnServer = async () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { server } = await import("./server")
  server.listen({
    onUnhandledRequest: "bypass",
  })
}

export const initAPIMockOnClient = async () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = await import("./browser")
  await worker.start({
    onUnhandledRequest: "bypass",
  })
}
