// @todo https://github.com/trenders/prj-tieup-front/issues/2121 data.historiesの型付
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, useReducer } from "react"
import immer from "immer"
import { ClientUserState, EmptyUserData, FirebaseUserData } from "types"
import { ApiUserData } from "src/models/user/ApiUserData"
import { emptyUserData } from "src/components/FirebaseUserData"

export interface GlobalStore {
  /** スクロールロック */
  scrollLock: boolean
  /** ローディング  */
  loading: {
    /** 全画面ローディング */
    fullScreenLoading: boolean
  }
  /** UI */
  ui: {
    globalHeader: {
      visible: boolean
    }
    globalSpMenu: {
      visible: boolean
    }
    modal: {
      creatorSearch: boolean
      registerDialog: boolean
    }
  }
  /** ユーザー情報 */
  user: {
    /** Firebaseユーザー情報 */
    firebaseUserData: FirebaseUserData | EmptyUserData
    /** APIユーザー情報 */
    apiUserData: ApiUserData | null
    /** 有効化メールアドレス登録（変更）リクエストがある場合 true */
    hasActiveRequest: 0 | 1 | 2
    /** ユーザーステート */
    clientState: ClientUserState
  }

  /** 取得した一覧系のデータ */
  data: {
    histories: any | null
  }
  /** FCMが使えるかどうか */
  enableFCM: boolean
}

export type GlobalAction =
  | {
      type: "loading/fullScreenLoading"
      payload: boolean
    }
  | {
      type: "scrollLock"
      payload: boolean
    }
  | {
      type: "ui/globalHeader/visible"
      payload: boolean
    }
  | {
      type: "ui/globalSpMenu/visible"
      payload: boolean
    }
  | {
      type: "ui/modal/creatorSearch"
      payload: boolean
    }
  | {
      type: "ui/modal/registerDialog"
      payload: boolean
    }
  | {
      type: "user/firebaseUserData"
      payload: FirebaseUserData | EmptyUserData
    }
  | {
      type: "user/apiUserData"
      payload: ApiUserData | null
    }
  | {
      type: "user/hasActiveRequest"
      payload: boolean
    }
  | {
      type: "user/clientState"
      payload: ClientUserState
    }
  | {
      type: "user/resetUserData"
    }
  | {
      type: "data/histories"
      payload: any
    }
  | {
      type: "enableFCM"
      payload: boolean
    }

/** グローバルステートの初期値 */
export const initialState: GlobalStore = {
  scrollLock: false,
  loading: {
    fullScreenLoading: false,
  },
  ui: {
    globalHeader: {
      visible: false,
    },
    globalSpMenu: {
      visible: false,
    },
    modal: {
      creatorSearch: false,
      registerDialog: false,
    },
  },
  user: {
    firebaseUserData: emptyUserData,
    apiUserData: null,
    hasActiveRequest: 0,
    clientState: null,
  },
  data: {
    histories: [],
  },
  enableFCM: false,
}

/** reducer */
const reducer = immer((draft: GlobalStore, action: GlobalAction) => {
  switch (action.type) {
    case "loading/fullScreenLoading": {
      draft.loading.fullScreenLoading = action.payload
      break
    }
    case "scrollLock": {
      draft.scrollLock = action.payload
      break
    }
    case "ui/globalHeader/visible": {
      draft.ui.globalHeader.visible = action.payload
      break
    }
    case "ui/globalSpMenu/visible": {
      draft.ui.globalSpMenu.visible = action.payload
      break
    }
    case "ui/modal/creatorSearch": {
      draft.ui.modal.creatorSearch = action.payload
      break
    }
    case "ui/modal/registerDialog": {
      draft.ui.modal.registerDialog = action.payload
      break
    }
    case "user/firebaseUserData": {
      draft.user.firebaseUserData = action.payload
      break
    }
    case "user/apiUserData": {
      draft.user.apiUserData = action.payload
      break
    }
    case "user/hasActiveRequest": {
      draft.user.hasActiveRequest = action.payload ? 2 : 1
      break
    }
    case "user/clientState": {
      draft.user.clientState = action.payload
      break
    }
    case "user/resetUserData": {
      draft.user.firebaseUserData = emptyUserData
      draft.user.apiUserData = null
      draft.user.clientState = 0
      draft.user.hasActiveRequest = 1
      break
    }
    case "data/histories": {
      draft.data.histories = action.payload
      break
    }
    case "enableFCM": {
      draft.enableFCM = action.payload
    }
  }
})

interface StoreWithAction {
  globalStore: GlobalStore
  globalDispatch: Dispatch<GlobalAction>
}

export const globalStoreContext = React.createContext<StoreWithAction>({
  globalStore: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  globalDispatch: () => {},
})

const GlobalStoreProvider: React.FC = ({ children }) => {
  const [globalStore, globalDispatch] = useReducer(reducer, initialState)

  return (
    <globalStoreContext.Provider
      value={{ globalStore: globalStore as GlobalStore, globalDispatch }}
    >
      {children}
    </globalStoreContext.Provider>
  )
}

export default GlobalStoreProvider
