import * as Sentry from "@sentry/nextjs"

if (process.env.NEXT_PUBLIC_ENV !== "local") {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: "production",
    integrations: [new Sentry.Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
