import { NotificationResponse } from "../../api/v1/notification/GetResponse"
import { Notification } from "./Notification"

export const adaptNotification = (res: NotificationResponse): Notification => {
  return {
    ids: res.ids,
    count: res.count,
    createdAt: res.created_at,
    isUnread: res.read_at === null,
    notificationType: res.notification_type,
    destination: res.destination,
    title: res.title,
    description: res.description,
  }
}
