import { useContext } from "react"
import { globalStoreContext } from "../../store/GlobalStore"

const useLoading = () => {
  const { globalDispatch } = useContext(globalStoreContext)

  const fullScreenLoading = (state: boolean) => {
    globalDispatch({ type: "loading/fullScreenLoading", payload: state })
  }

  return {
    fullScreenLoading,
  }
}

export { useLoading }
