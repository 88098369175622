import { getMypageMailCheckRequest } from "../../api/v1/mypage/user/mail/check_request/get"
import { MypageUserMailCheckRequestGetInputModel } from "../../api/v1/mypage/user/mail/check_request/GetInputModel"

/** 認証メール確認 */
export const checkMailRequest = (): Promise<{ hasActiveRequest: boolean }> => {
  return getMypageMailCheckRequest(
    new MypageUserMailCheckRequestGetInputModel(),
  ).then((v) => {
    return {
      hasActiveRequest: v.has_active_request,
    }
  })
}
