import { httpPut } from "../../../../../libs/http/httpPut"
import { NotificationNotificationIdPutInputModel } from "./PutInputModel"

const endpointUrl = `/v1/notification/{notificationId}/read`

export const putNotificationNotificationIdRead = async (
  model: NotificationNotificationIdPutInputModel,
): Promise<void> => {
  return httpPut(endpointUrl, model)
}
