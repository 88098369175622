import { ReactNode } from "react"
import { useToasts } from "react-toast-notifications"

const useTieupToast = () => {
  const { addToast, removeAllToasts } = useToasts()

  /** エラー時 */
  const addToastError = (text: ReactNode, dismiss = false, id?: string) => {
    addToast(text, {
      appearance: "error",
      autoDismiss: dismiss,
      id,
    })
  }

  /**
   *  通知時、成功時
   *  指定時間(msec)が引数にセットされている場合、指定時間後に削除する
   */
  const addToastInfo = (text: ReactNode, dismiss = true, mSec?: number) => {
    addToast(text, {
      appearance: "info",
      autoDismiss: dismiss,
    })

    // mSec後に toast を削除する
    if (mSec != undefined) {
      setTimeout(() => {
        removeAllToasts()
      }, mSec)
    }
  }

  /** 警告 */
  const addToastWarning = (text: ReactNode) => {
    addToast(text, {
      appearance: "warning",
      autoDismiss: true,
    })
  }

  return {
    addToastError,
    addToastInfo,
    addToastWarning,
    removeAllToasts,
  }
}

export { useTieupToast }
