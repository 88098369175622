import { putNotificationNotificationIdRead } from "../../api/v1/notification/[notification_id]/read/put"
import { NotificationNotificationIdPutInputModel } from "../../api/v1/notification/[notification_id]/read/PutInputModel"

export const markReadToNotification = (
  notificationId: number,
): Promise<void> => {
  return putNotificationNotificationIdRead(
    new NotificationNotificationIdPutInputModel(notificationId),
  )
}
