import { SizeType } from "./SizeType"

export const getTieupImgSrc = (src: string, size?: SizeType) => {
  if (!src) {
    return
  }
  if (/^blob/.test(src) || /^http/.test(src)) {
    return src
  }

  if (src && size) {
    const sizedSrc = src.replace(/^(.+)(\.[a-z]+)$/, "$1" + "." + size + "$2")

    return `${process.env.NEXT_PUBLIC_S3_URL}${sizedSrc}`
  }

  return `${process.env.NEXT_PUBLIC_S3_URL}${src}`
}
