import { BasePutInputModel } from "../../../../../models/http/InputModel/BasePutInputModel"

export class NotificationNotificationIdPutInputModel extends BasePutInputModel {
  constructor(private notificationId: number) {
    super()
  }

  get pathParams(): Record<string, string> {
    return {
      notificationId: this.notificationId.toString(),
    }
  }
}
