import { MyPageUserGetResponse } from "./GetResponse"
import { MypageUserGetInputModel } from "./GetInputModel"
import { httpGet } from "../../../../libs/http/httpGet"

const endpointUrl = "/v1/mypage/user"

export const getMypageUser = (
  model: MypageUserGetInputModel,
): Promise<MyPageUserGetResponse> => {
  return httpGet<MyPageUserGetResponse>(endpointUrl, model)
}
