import { BaseInputModel } from "./BaseInputModel"

export abstract class BaseGetInputModel extends BaseInputModel {
  get query(): URLSearchParams {
    return new URLSearchParams()
  }

  get searchString(): string {
    return this.query.toString() ? `?${this.query.toString()}` : ""
  }
}
