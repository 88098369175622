import React, { createContext, Dispatch, useReducer } from "react"
import { GiftItem } from "../models/gift/GiftItem"
import immer from "immer"

type PurchaseGiftStore = {
  purchasedGiftItem: GiftItem | undefined
}
type Action = {
  type: "purchasedGiftItem"
  payload: GiftItem
}

/** reducer */
const reducer = immer((draft: PurchaseGiftStore, action: Action) => {
  switch (action.type) {
    case "purchasedGiftItem": {
      draft.purchasedGiftItem = action.payload
    }
  }
})

const initialState: PurchaseGiftStore = {
  purchasedGiftItem: undefined,
}
export const purchaseGiftContext = createContext<{
  purchasedGiftStore: PurchaseGiftStore
  dispatch: Dispatch<Action>
}>({
  purchasedGiftStore: initialState,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
})

const PurchaseGiftStoreProvider: React.FC = ({ children }) => {
  const [purchasedGiftStore, dispatch] = useReducer(reducer, initialState)

  return (
    <purchaseGiftContext.Provider value={{ purchasedGiftStore, dispatch }}>
      {children}
    </purchaseGiftContext.Provider>
  )
}

export default PurchaseGiftStoreProvider
