import GlobalFooter from "./common/GlobalFooter"
import TieupLogo from "./common/TieupLogo"

const ErrorFallback: React.FC = () => {
  return (
    <>
      <div className="error l-single -small">
        <div style={{ margin: "50px 0", maxWidth: "160px" }}>
          <TieupLogo />
        </div>
        <p className="error-subtitle">
          <span>システムエラー</span>
        </p>
        <p className="error-text">エラーが発生しました</p>
        <p className="error-link">
          <a href="/" className="c-textLink-gray">
            トップページへ戻る
          </a>
        </p>
      </div>
      <GlobalFooter />
    </>
  )
}

export default ErrorFallback
