/**
 * Firebase Cloud Messaging
 */
import { useContext, useMemo } from "react"
import { globalStoreContext } from "src/store/GlobalStore"
import useSWR, { mutate } from "swr"
import { fetchNotifications } from "../../models/Notification/fetchNotifications"
import { markReadToNotification } from "../../models/Notification/markReadToNotification"

const sinceDays = 7

const useFCM = () => {
  const {
    globalStore: {
      user: { apiUserData },
      enableFCM,
    },
  } = useContext(globalStoreContext)

  const { data: notifications } = useSWR(
    apiUserData?.display_name
      ? `/v1/notification?since_days=${sinceDays}`
      : null,
    async () => {
      return fetchNotifications(sinceDays)
    },
    {
      // ポーリング時間
      refreshInterval: enableFCM
        ? 0 // 0: 無効
        : parseInt(process.env.NEXT_PUBLIC_POLLING_TIME_MS as string, 10),
    },
  )

  /** 未読の最初 */
  const firstUnread = useMemo(() => {
    if (!notifications) {
      return null
    }
    const filtered = notifications.filter((v) => v.isUnread)

    return filtered[0] || null
  }, [notifications])

  /** 一覧を再取得する */
  const mutateFCM = async () => {
    return await mutate(`/v1/notification`)
  }

  /** 配列で指定したidの通知を既読にする */
  const read = async (notificationIds: number[]) => {
    try {
      for (const id of notificationIds) {
        await markReadToNotification(id)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw error
    }
  }

  /** 未読が存在するか？ */
  const hasUnread = useMemo(() => {
    return notifications !== undefined
      ? notifications.some((v) => v.isUnread)
      : false
  }, [notifications])

  /** 未読数を取得 */
  const unreadNoticeCount = useMemo(() => {
    return notifications !== undefined
      ? notifications.filter((v) => v.isUnread).length
      : 0
  }, [notifications])

  return {
    notifications: notifications || [],
    mutateFCM,
    hasUnread,
    read,
    firstUnread,
    unreadNoticeCount,
  }
}

export { useFCM }
