import { getNotification } from "../../api/v1/notification/get"
import { NotificationGetInputModel } from "../../api/v1/notification/GetInputModel"
import { Notification } from "./Notification"
import { adaptNotification } from "./adaptNotification"

export const fetchNotifications = (since: number): Promise<Notification[]> => {
  return getNotification(new NotificationGetInputModel({ since })).then(
    (res) => {
      return res.map(adaptNotification)
    },
  )
}
