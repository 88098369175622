export default {
  grayScale: {
    white: "#FFFFFF",
    customGray: "#EFEFF4",
    lightGray: "#E5E5EA",
    lightGray2: "#D1D1D6",
    midGray: "#C7C7CC",
    gray: "#8E8E93",
    lightBlack: "#282728",
    black: "#000000",
  },
  spectrum: {
    trueRed: "#E60012",
    tieupTheme: "#F15353",
    skyBlue: "#00AFEC",
    cornYellow: "#F9C049",
    ultramarineBlue: "#4051B6",
    ashBlue: "#CED3ED",
    checkGreen: "#00b900",
  },
}
