/**
 * ユーザーのステート状態を設定する
 */

import { useContext, useEffect } from "react"
import { globalStoreContext } from "src/store/GlobalStore"
import { emptyUserData } from "./FirebaseUserData"

const ClientUserState: React.FC = ({ children }) => {
  const {
    globalStore: {
      user: { firebaseUserData, apiUserData },
    },
    globalDispatch,
  } = useContext(globalStoreContext)

  useEffect(() => {
    // Firebase 未ログイン: 0
    if (firebaseUserData === emptyUserData) {
      globalDispatch({ type: "user/clientState", payload: 0 })

      return
    }

    // 新規会員登録を実行し、未完了: 1
    if (firebaseUserData && apiUserData && !apiUserData.mail_address) {
      globalDispatch({ type: "user/clientState", payload: 1 })

      return
    }

    // ログイン完了（メンバー）: 2
    if (firebaseUserData && apiUserData && apiUserData.mail_address) {
      globalDispatch({ type: "user/clientState", payload: 2 })

      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseUserData, apiUserData])

  return <>{children}</>
}

export default ClientUserState
