import { BasePostInputModel } from "../../../../models/http/InputModel/BasePostInputModel"

export class MypageUserFcmTokenPostInputModel extends BasePostInputModel {
  constructor(private token: string) {
    super()
  }

  get body(): Record<string, string> {
    return {
      fcm_token: this.token,
    }
  }
}
